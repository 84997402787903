import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.png'

const year = () => {
  const thisYear = new Date()
  return thisYear.getFullYear()
}
const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <div>
        <div>
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <p style={{ marginBottom: '1rem' }}>
            info@transferrum.sk
            <br />
            martin@transferrum.sk
            <br />
            transferrum@post.sk
          </p>
        </div>
        <div className="footer-reverse">
          <ul className="links">
            <li>
              <Link to="/spracovanie-osobnych-udajov">
                Spracovanie osobných údajov
              </Link>
            </li>
            <li>
              <Link to="/cenova-ponuka">Kontakt - Cenová ponuka</Link>
            </li>
            <li>
              <Link to="/galeria">Galéria</Link>
            </li>
          </ul>
          <ul className="copyright">
            <li>
              Copyright &copy;
              {year()} <a href="https://transferrum.sk">TRANSFERRUM</a>
            </li>
            <li>
              <a
                href="http://acsella.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Design+Code ACSELLA
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="contact-hot">
        <p>
          Budovateľská 18 <br />
          064 01 Stará Ľubovňa
        </p>

        <p>
          <strong>Marián Kaleta </strong>
          <br />
          +421 905 235 468
          <br />
          <strong>Martin Kaleta </strong>
          <br />
          +421 907 094 801
          <br />
          <strong>Predajňa</strong>
          <br /> 052 4369019{' '}
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
